<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }} </ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <ion-row>
          <ion-col></ion-col>
          <ion-col></ion-col>
          <ion-col>  
            <ion-button color="dark" style="width: 45px;" @click="openModalAdd">
              +
            </ion-button>
          </ion-col>
        </ion-row>
        <div style="margin-left: 24px;margin-right: 24px;">
          <div class="card">
            
              <table class="table table-responsive-sm table-striped table-align-middle" style="display: inline-table !important;">
                <thead>
                  <tr>
                    <th >Nombre del Usuario</th>
                    <th >Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="administrador in administradores.data" :key="administrador">
                    <td>{{administrador?.user_name}}</td>
                    <td>{{administrador?.email}}</td>
                    <td>
                      <ion-button color="primary"  @click="openModalEdit(administrador)">
                        Editar
                      </ion-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="page navigation example">
                <ul class="pagination justify-content-center">
                   <li  
                    :class="{'page-item' : true , 'disabled' : !administradores.first_page_url }"
                  >
                    <a 
                      class="page-link"   
                      @click="page(administradores.first_page_url)" 
                      tabindex="-1"
                    >
                      Primero
                    </a>
                  </li>
                  <li 
                    v-for="link in administradores.links" 
                    :key="link"
                    :class="{'page-item' : true , 'active' : link.active }" 
                  >
                    <a
                      class="page-link" 
                      @click="page(link.url)" 
                      tabindex="-1"
                      v-html="link.label"
                    >
                      
                    </a>
                  </li>
                  <li 
                    :class="{'page-item' : true , 'disabled' : !administradores.last_page_url }"
                  >
                    <a 
                      class="page-link"  
                      @click="page(administradores.last_page_url)"
                    >
                      Ultimo
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { modalController,createAnimation } from '@ionic/vue';

import axios from 'axios'
import agregarAdministrador from './agregarSubAdministrador';
import editarAdministrador from './editarSubAdministrador';

export default defineComponent({
  name: 'Administradores',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
  },
  data(){
    return{
      administradores : []
    }
  },
  mounted(){
    this.getAdministradores()
  },
  methods:{
    getAdministradores(){
      axios.get('/api/users?role=Despacho')
      .then(res => {
        console.log(res.data.data)
        this.administradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    page(url){
      
      if(url == null){
        return
      }

      axios.get(url+'&role=Despacho')
       .then(res => {
         this.administradores = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
     async openModalAdd() {
    
      const modal = await modalController
        .create({
          component: agregarAdministrador,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
        })


   
      modal.present();

      modal.onDidDismiss().then((data) => {
        this.getAdministradores()
        console.log(data)
      })

    },
    async openModalEdit(administrador) {
    
      const modal = await modalController
        .create({
          component: editarAdministrador,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {administrador : administrador}
        })


   
      modal.present();
      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getAdministradores()
      })

    },
     enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style scoped>

</style>
