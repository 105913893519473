<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Editar Despacho </ion-title>
    <ion-form>
      <ion-input type="text" id="user"   v-model="user"   placeholder="Ingrese el Usuario" />
      <ion-input type="email" id="email"   v-model="email"   placeholder="Ingrese el email" />
      <ion-input type="password" id="password" v-model="password"   placeholder="Ingrese la contraseña" />
      <ion-input type="password" id="password_confirmation" v-model="password_confirmation"   placeholder="Repite la contraseña" />
      <ion-button color="dark" @click="editColaborador">
        Enviar
      </ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonInput } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'AgregarColaborador',
  props : ['administrador'],
  components :{IonInput},
  setup() {
    return { close }
  },
  data(){
    return{
      uid : null,
      user: null,
      email : null,
      password: null,
    }
  },
  mounted(){
    this.id = this.administrador.id
    this.user = this.administrador.user_name
    this.email = this.administrador.email
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    async editColaborador(){
      var loading = await toast.showLoading()

      await loading.present();

      axios.post('/api/users/'+this.id,{
        user_name : this.user,
        email : this.email,
        password : this.password,
        password_confirmation : this.password_confirmation
      })
      .then(data =>{
         loading.dismiss()

         this.closeModal()
        console.log(data)
        this.$toast.openToast("Modificacion de Sub Administrador exitosa","success")
      }).catch(err =>{
        loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>
